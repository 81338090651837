<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-4">
          <button class="btn btn-sm btn-link" @click="displayForm">
            <span v-if="!createEditItem">Ajouter une question/réponse</span>
            <span v-else>Annuler</span>
          </button>
        </div>
      </div>
    </div>

    <div class="row" v-if="createEditItem">
      <div class="col-12">
        <div class="mb-4">
          <item-form
            :edit-mode="isEdition"
            :initial-values="form"
            @save="handleSave"
            @formStatusChanged="formStatusChanged"
          />
        </div>
      </div>
    </div>

    <div v-else>
      <div>
        <div class="mb-4">
          <div v-if="orderedItems.length === 0" class="text-center">
            <h4 class="header-title">Vous n'avez aucune question/réponse liée à votre FAQ</h4>
          </div>
          <collection-table
            v-else
            :cols="cols"
            :list-items="orderedItems"
            :show-actions="true"
            no-top-border
            :draggable="true"
            @edit="handleEdit"
            @delete="handleDelete"
            @order="handleOrder"
            hover
          ></collection-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import useValidator from '@/mixins/useValidator'
import ItemForm from './ItemForm'
import ItemRow from './ItemRow'

export default {
  components: {
    ItemForm
  },
  mixins: [useValidator],
  props: {
    items: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      createEditItem: false,
      isEdition: false,
      form: {}
    }
  },
  computed: {
    orderedItems () {
      return orderBy(this.items, ['order'], ['asc'])
    },
    cols () {
      return [
        {
          header: 'Questions',
          property: 'id',
          component: ItemRow,
          props: (item) => {
            return {
              item
            }
          }
        }
      ]
    }
  },
  methods: {
    displayForm () {
      this.isEdition = false

      if (this.createEditItem) {
        this.createEditItem = false
        this.form = {}
        this.$emit('formStatusChanged', false)
      } else {
        this.createEditItem = true
      }
    },
    formStatusChanged (status) {
      this.$emit('formStatusChanged', status)
    },
    handleSave ({ form, isValid }) {
      if (isValid) {
        this.createEditItem = false
        this.form = {
          ...this.form,
          ...form
        }

        if (this.form.id) {
          this.$emit('edit', this.form)
        } else {
          this.$emit('add', this.form)
        }

        this.form = {}
      }
    },
    handleEdit (form) {
      this.form = form
      this.createEditItem = true
      this.isEdition = true
    },
    async handleDelete (item) {
      try {
        this.hasError = false

        await this.$modal.openConfirmModal({
          title: "Supprimer l'élément",
          message: `Voulez-vous supprimer la question: ${item.question}`,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$emit('delete', item)
      } catch {}
    },
    handleOrder ({ oldIndex, newIndex }) {
      this.$emit('order', { oldIndex, newIndex })
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-table {
  margin-bottom: 0;

  &.border-top-none {
    thead {
      th {
        border-top: 0;
      }
    }
  }

  th {
    font-size: 14px;
    white-space: nowrap;
  }

  .content {
    width: 90%;
  }
}
</style>
