<template>
  <app-layout>
    <div class="px-5 container container-center">
      <div class="heading d-flex justify-content-between mb-5">
        <h2 v-if="editMode">Editer la catégorie</h2>
        <h2 v-else>Nouvelle catégorie</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" :to="{ name: 'product-categories.index' }" >
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <async-object
        :should-fetch="editMode"
        :fetch-method="fetchCategory"
      >

        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="p-3 card">
              <v-dynamic-form
                :fields="fields"
                :initial-values="form"
                @change="handleChange"
                :errors="errors"
              ></v-dynamic-form>
              <p class="pt-1">
                Parent: <b>{{ parentName }}</b>
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <button class="btn btn-primary" :disabled="isSubmit" @click="submit">Enregistrer</button>
          </div>
        </div>

        <div v-if="editMode" class="row mt-4">
          <div class="col-12">
            <div v-if="orderedChildren.length === 0" class="p-3 card">
              <div class="row">
                <div class="col-12 text-center pt-2">
                  <h4 class="header-title">Vous n'avez aucune catégorie enfant</h4>
                </div>
              </div>
            </div>
            <div v-else class="p-3 card">
              <div class="row">
                <div class="col-12">
                  <h4 class="header-title">Ordre des catégories enfants</h4>
                </div>
              </div>
              <collection-table
                class="cursor"
                :cols="cols"
                :list-items="orderedChildren"
                :show-actions="false"
                no-top-border
                :draggable="true"
                @order="handleOrder"
                hover
              ></collection-table>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { orderBy } from 'lodash-es'
import AsyncObject from '@/components/common/AsyncObject'
import promptUnsaved from '@/mixins/promptUnsaved'
import useValidator from '@/mixins/useValidator'

export default {
  components: {
    AsyncObject
  },
  mixins: [promptUnsaved, useValidator],
  data () {
    return {
      hasError: false,
      isSubmit: false,
      isReady: false,
      form: {
        parentId: null
      },
      parentName: 'Racine'
    }
  },
  computed: {
    editMode () {
      return !!this.$route.params.id
    },
    fields () {
      return [
        {
          name: 'name',
          label: 'Nom',
          type: 'text',
          required: true
        },
        {
          name: 'slug',
          label: 'Slug',
          type: 'text',
          required: true
        }
      ]
    },
    validationRules () {
      return {
        name: 'required',
        slug: 'required|slug'
      }
    },
    category () {
      return this.$store.getters['productCategories/getDetailsById'](this.$route.params.id)
    },
    cols () {
      return [
        {
          header: 'Name',
          property: 'name'
        },
        {
          header: 'slug',
          property: 'slug'
        }
      ]
    },
    orderedChildren () {
      let children = []

      if (this.category) {
        children = this.category.children
      }

      return orderBy(children, ['order'], ['asc'])
    }
  },
  methods: {
    handleOrder ({ oldIndex, newIndex }) {
      this.$store.dispatch('productCategories/orderChildrenItems', { id: this.$route.params.id, oldIndex, newIndex })
    },
    handleChange (form) {
      if (this.isReady) {
        this.formStatusChanged(true)
      } else {
        this.isReady = true
      }
      this.form = form
    },
    async fetchCategory () {
      await this.$store.dispatch('productCategories/fetchById', this.$route.params.id)
      this.form = this.category
      this.setParentName(this.category.parentId)
    },
    async submit () {
      this.hasError = false
      this.isSubmit = true

      try {
        if (!this.validate(this.form, this.validationRules)) {
          this.isSubmit = false
          return
        }

        if (this.editMode) {
          await this.$store.dispatch('productCategories/update', { ...this.category, ...this.form })
        } else {
          const order = await this.$store.dispatch('productCategories/getOrder', { parentId: this.form.parentId })

          await this.$store.dispatch('productCategories/create', { ...this.form, order })
        }

        this.$toasted.success('Catégorie enregistrée avec succès.')

        this.formStatusChanged(false)
        this.isSubmit = false

        this.$router.push({ name: 'product-categories.index' })
      } catch (e) {
        this.isSubmit = false
        this.hasError = true
      }
    },
    async setParentName (parentId) {
      const parent = await this.$store.dispatch('productCategories/getParent', { parentId })

      if (parent) {
        this.parentName = parent.name
      }
    }
  },
  async created () {
    await this.$store.dispatch('productCategories/fetchOrRefresh')

    if (this.$route.query.parentId) {
      this.form.parentId = this.$route.query.parentId
      this.setParentName(this.$route.query.parentId)
    }
  }
}
</script>

<style lang="scss">
.cursor > table > tbody {
  cursor: grab;
}
</style>
