<template>
  <div>
    <v-dynamic-form
      :fields="fields"
      :initial-values="initialValues"
      @change="handleChange"
      :errors="errors"
    ></v-dynamic-form>

    <button class="btn btn-primary" :disabled="isSubmit" @click="handleSave">
      <span v-if="editMode">Enregister</span>
      <span v-else>Ajouter</span>
    </button>
  </div>
</template>

<script>
import useValidator from '@/mixins/useValidator'

export default {
  mixins: [useValidator],
  data () {
    return {
      form: {},
      isSubmit: false
    }
  },
  props: {
    initialValues: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'question',
          label: 'Question',
          type: 'text',
          required: true
        },
        {
          name: 'answer',
          label: 'Réponse',
          type: 'editor',
          required: true,
          helpText: 'Résultat de la réponse lors de l\'affichage'
        }
      ]
    },
    validationRules () {
      return {
        question: 'required',
        answer: 'required'
      }
    }
  },
  methods: {
    handleChange (form) {
      if (Object.entries(this.form).length > 0) {
        this.$emit('formStatusChanged', true)
      }
      this.form = form
    },
    handleSave () {
      const isValid = this.validate(this.form, this.validationRules)
      this.isSubmit = isValid
      this.$emit('save', { form: this.form, isValid })
    }
  }
}
</script>

<style scoped>
</style>
