<template>
  <div>
    <div v-b-toggle="`item-${item.order}`">
      <b>Question:</b> {{ item.question }}
    </div>
    <b-collapse :id="`item-${item.order}`" class="pt-3">
      <b>Réponse:</b>
      <div v-html="preview(item.answer)"></div>
    </b-collapse>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import marked from 'marked'
import { BCollapse, VBToggle } from 'bootstrap-vue'

export default {
  components: {
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: ['item'],
  methods: {
    preview (code) {
      return sanitizeHtml(marked(code, { breaks: true, gfm: true }))
    }
  }
}
</script>
