<template>
  <div>
    <ul class="tree">
      <li v-if="isLoading">
        <template>
          <b-spinner></b-spinner>
        </template>
      </li>
      <draggable
        v-else
        v-model="elements"
        group="product-categories"
        @change="handleChangeOrder"
        :disabled="!isDraggable"
        tag="li"
      >
      <div class="pt-2" :class="{ disable: !isDraggable }" v-for="item in elements" :key="item.id" >
        <div class="d-flex flex-row">
          <div class="mr-4 d-flex flex-row cursor" @click="openClose(item.id)">
            <div class="mr-2">
              <fa-icon v-if="isOpen(item.id)" :icon="['fas', 'chevron-down']"></fa-icon>
              <fa-icon v-else :icon="['fas', 'chevron-right']"></fa-icon>
            </div>
            <div>{{ item.name }} ({{ item.slug }})</div>
          </div>
          <div>
            <fa-icon class="mr-2 text-primary cursor" @click="addItem(item)" :icon="['fas', 'plus']"></fa-icon>
            <fa-icon class="mr-2 text-warning cursor" @click="editItem(item)" :icon="['fas', 'pen']"></fa-icon>
            <fa-icon class="text-danger cursor" @click="deleteItem(item)" :icon="['fas', 'trash']"></fa-icon>
          </div>
        </div>
        <category-tree
          v-if="isOpen(item.id)"
          :items="item.children"
          :parent-id="item.id"
          :is-draggable="isDraggable"
          @enable="enableDragAndDrop"
        />
      </div>
      <div class="pt-2" v-if="elements.length === 0">
        Pas de sous-catégorie
      </div>
      </draggable>
    </ul>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'
import Draggable from 'vuedraggable'
import CategoryTree from './CategoryTree'
import { move } from '@/utils/move'

export default {
  name: 'category-tree',
  components: {
    'category-tree': CategoryTree,
    Draggable
  },
  data () {
    return {
      isLoading: true,
      openList: [],
      elements: []
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    parentId: {
      type: String,
      required: false,
      default: null
    },
    isDraggable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    enableDragAndDrop (status) {
      this.$emit('enable', status)
    },
    async handleChangeOrder (evt) {
      this.$emit('enable', false)

      if (evt.moved) {
        move([...this.elements], evt.moved.oldIndex, evt.moved.newIndex)
      }

      this.elements.forEach((element, index) => {
        element.parentId = this.parentId
        element.order = index + 1
      })

      if (evt.moved) {
        await this.$store.dispatch('productCategories/orderChildren', {
          children: [
            this.elements[evt.moved.oldIndex],
            this.elements[evt.moved.newIndex]
          ]
        })
      } else if (evt.removed) {
        this.elements = this.elements.filter((e) => e.id !== evt.removed.element.id)
      } else {
        await this.$store.dispatch('productCategories/orderChildren', { children: this.elements })
      }

      if (evt.moved || evt.removed) {
        this.$emit('enable', true)
      }
    },
    isOpen (id) {
      return this.openList.includes(id)
    },
    openClose (id) {
      if (this.isOpen(id)) {
        this.openList = this.openList.filter((e) => e !== id)
      } else {
        this.openList.push(id)
      }
    },
    addItem (item) {
      this.$router.push({ name: 'product-categories.create', query: { parentId: item.id } })
    },
    editItem (item) {
      this.$router.push({ name: 'product-categories.edit', params: { id: item.id } })
    },
    async deleteItem (item) {
      try {
        this.hasError = false

        await this.$modal.openConfirmModal({
          title: 'Supprimer la catégorie',
          message: `Voulez-vous supprimer la catégorie: ${item.name}?`,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.elements = this.elements.filter((e) => e.id !== item.id)

        await this.$store.dispatch('productCategories/delete', item.id)
      } catch (error) {
        this.hasError = error !== 'ACTION_CANCELLED'
      }
    }
  },
  async created () {
    if (this.parentId && this.items.length === 0) {
      const elements = await this.$store.dispatch('productCategories/fetchChildren', { parentId: this.parentId })
      this.elements = orderBy(elements, ['order'], ['asc'])
      this.isLoading = false
    } else {
      this.elements = orderBy(this.items, ['order'], ['asc'])
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tree {
  list-style-type: none;

  .cursor {
    cursor: pointer;
  }

  .disable {
    color: #666666;
  }
}
</style>
