<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Catégories</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 d-flex justify-content-between">
          <div class="row">
            <div class="col-12">
              <div class="form-inline">
                <div class="form-group mobile-wid-100">
                  <input v-model="textFilter" type="text" class="form-control mobile-wid-100" placeholder="Filtrer...">
                </div>
              </div>
            </div>
          </div>

          <div>
            <button class="btn btn-primary" @click="$router.push({ name: 'faq-categories.create' })">Ajouter</button>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="faqCategories.length === 0" class="card-body text-center">
              <h4 class="header-title">Vous n'avez aucune catégorie</h4>
            </div>
            <collection-table
              v-else
              :cols="cols"
              :list-items="filteredfaqCategories"
              :show-actions="true"
              @edit="editItem"
              @delete="deleteItem"
              no-top-border
              hover
              :link="(item) => `/faq-categories/edit/${item.id}`"
            ></collection-table>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { filter } from 'lodash-es'
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'

export default {
  mixins: [formatsDate, formatsCurrency],
  data () {
    return {
      hasError: false
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.faqCategories.isLoading
    },
    faqCategories () {
      return this.$store.state.faqCategories.collection
    },
    filteredfaqCategories () {
      let items = this.faqCategories || []

      if (this.textFilter) {
        const searchString = this.textFilter.toLowerCase()

        items = filter(items, (item) => {
          return (
            item.name.toLowerCase().indexOf(searchString) > -1 ||
            item.code.toLowerCase().indexOf(searchString) > -1 ||
            item.type.toLowerCase().indexOf(searchString) > -1
          )
        })
      }

      return items
    },
    cols () {
      return [
        {
          header: 'Nom',
          property: 'name'
        },
        {
          header: 'Code',
          property: 'code'
        },
        {
          header: 'Type',
          property: 'type',
          method: (item) => {
            return item.type === 'customer' ? 'Client' : 'Vendeur'
          }
        },
        {
          header: "Nombre d'éléments",
          property: 'faqItems',
          method: (item) => {
            return item.faqItems ? item.faqItems.length : 0
          }
        }
      ]
    },
    textFilter: {
      get () {
        return this.$store.state.faqCategories.textFilter
      },
      set (value) {
        this.$store.commit('faqCategories/SET_TEXT_FILTER', value)
      }
    }
  },
  methods: {
    editItem (item) {
      this.$router.push({ name: 'faq-categories.edit', params: { id: item.id } })
    },
    async deleteItem (item) {
      try {
        this.hasError = false

        await this.$modal.openConfirmModal({
          title: 'Supprimer la catégorie',
          message: `Voulez-vous supprimer la catégorie: ${item.name}?`,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$store.dispatch('faqCategories/delete', item.id)
      } catch (error) {
        this.hasError = error !== 'ACTION_CANCELLED'
      }
    }
  },
  created () {
    this.$store.dispatch('faqCategories/fetchOrRefresh')
  }
}
</script>
