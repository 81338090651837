<template>
  <app-layout>
    <div class="px-5 container container-center">
      <div class="heading d-flex justify-content-between mb-5">
        <h2 v-if="editMode">Editer la catégorie</h2>
        <h2 v-else>Nouvelle catégorie</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" :to="{ name: 'faq-categories.index' }" >
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <async-object
        :should-fetch="editMode"
        :fetch-method="fetchCategory"
      >

        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="p-3 card">
              <v-dynamic-form
                :fields="fields"
                :initial-values="form"
                @change="handleChange"
                :errors="errors"
              ></v-dynamic-form>
              <div>
                <button class="btn btn-primary" :disabled="isSubmit" @click="submit">Enregistrer</button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="editMode" class="row my-4">
          <div class="col-12">
            <div class="p-3 card">
              <category-item
                :items="orderedItems"
                @add="addItem"
                @edit="editItem"
                @delete="deleteItem"
                @order="orderItem"
                @formStatusChanged="formStatusChanged"
              />
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import CategoryItem from '@/components/categories/Item'
import promptUnsaved from '@/mixins/promptUnsaved'
import useValidator from '@/mixins/useValidator'

export default {
  components: {
    AsyncObject,
    CategoryItem
  },
  mixins: [promptUnsaved, useValidator],
  data () {
    return {
      hasError: false,
      isSubmit: false,
      isReady: false,
      form: {}
    }
  },
  computed: {
    editMode () {
      return !!this.$route.params.id
    },
    fields () {
      return [
        {
          name: 'name',
          label: 'Nom de la catégorie',
          type: 'text',
          required: true
        },
        {
          name: 'code',
          label: 'Code',
          type: 'text',
          required: true,
          helpText: 'Code système, alphanumérique seulement'
        },
        {
          name: 'type',
          label: 'Type',
          type: 'category-type-selector',
          required: true
        }
      ]
    },
    validationRules () {
      return {
        name: 'required',
        code: 'required|slug',
        type: 'required'
      }
    },
    orderedItems () {
      return this.category ? this.category.faqItems : []
    },
    category () {
      return this.$store.getters['faqCategories/getDetailsById'](this.$route.params.id)
    }
  },
  methods: {
    async addItem (form) {
      this.$store.dispatch('faqCategories/createItem', { form, id: this.$route.params.id })
      this.formStatusChanged(false)
      this.$toasted.success('Question ajoutée avec succès.')
    },
    editItem (form) {
      this.$store.dispatch('faqCategories/editItem', form)
      this.formStatusChanged(false)
      this.$toasted.success('Question mise à jour avec succès.')
    },
    deleteItem (item) {
      this.$store.dispatch('faqCategories/deleteItem', item)
      this.$toasted.success('Question supprimée avec succès.')
    },
    orderItem ({ oldIndex, newIndex }) {
      this.$store.dispatch('faqCategories/orderItems', { id: this.$route.params.id, oldIndex, newIndex })
    },
    handleChange (form) {
      if (this.isReady) {
        this.formStatusChanged(true)
      } else {
        this.isReady = true
      }
      this.form = form
    },
    async fetchCategory () {
      await this.$store.dispatch('faqCategories/fetchById', this.$route.params.id)
      this.form = this.category
    },
    async submit () {
      this.hasError = false
      this.isSubmit = true

      try {
        if (!this.validate(this.form, this.validationRules)) {
          this.isSubmit = false
          return
        }

        let id = null

        if (this.editMode) {
          await this.$store.dispatch('faqCategories/update', { ...this.form, id: this.category.id })
        } else {
          id = (await this.$store.dispatch('faqCategories/create', this.form)).id
          await this.$store.dispatch('faqCategories/fetchById', id)
        }

        this.$toasted.success('Catégorie enregistrée avec succès.')

        this.formStatusChanged(false)
        this.isSubmit = false

        if (id) {
          this.$router.push({ name: 'faq-categories.edit', params: { id } })
        }
      } catch (e) {
        this.isSubmit = false

        if (e.response.status === 400) {
          this.errors = JSON.parse(e.response.data)
        } else {
          this.hasError = true
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
