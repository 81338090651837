<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Catégories</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div v-if="hasError" class="row">
          <div class="col-12">
            <div class="mb-4">
              <div class="alert alert-danger">
                Une erreur interne s'est produite. Réessayez dans quelques instants.
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4 d-flex justify-content-between">
          <div>
            <button class="btn btn-primary" @click="$router.push({ name: 'product-categories.create' })">Ajouter une catégorie à la racine</button>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <div v-if="productCategories.length === 0" class="card-body text-center">
              <h4 class="header-title pt-2">Vous n'avez aucune catégorie</h4>
            </div>
            <div class="pt-3 pb-2" v-else>
              <category-tree
                :items="productCategories"
                :is-draggable="isDraggable"
                @enable="enableDragAndDrop"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import { orderBy } from 'lodash-es'
import CategoryTree from '@/components/products/CategoryTree'

export default {
  components: {
    CategoryTree
  },
  data () {
    return {
      hasError: false,
      isDraggable: true
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.productCategories.isLoading
    },
    productCategories () {
      return orderBy(this.$store.state.productCategories.collection, ['order'], ['asc'])
    }
  },
  methods: {
    enableDragAndDrop (draggable) {
      this.isDraggable = draggable
      if (draggable) {
        this.$toasted.success('Catégorie mise à jour avec succès.')
      }
    }
  },
  created () {
    this.$store.dispatch('productCategories/fetchOrRefresh')
  }
}
</script>
